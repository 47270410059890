//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ServiceCard",

  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    serviceIcon: function () {
      return String(this.service?.service_icon_id || '')
    },
    servicePrice: function () {
      const currency = this.$store.getters["currency/currentCurrency"];
      return [
        this.$convertCurrency(this.service.price),
        Boolean(currency === 'rub') ? '₽' : '$'
      ].join('')
    },
  },

  methods: {
    onBuyNumber: function () {
      this.$emit("buy", this.service)
    }
  }
}
